import schema from "@/http/apollo/schema";

export default {
  fullUser(values) {
    return {
      province: values.province || undefined,
      postalCode: values.postalCode || undefined,
      complement: values.complement || undefined,
      cpfCnpj: values.cpfCnpj || undefined,
      stateRegistration: values.stateRegistration || undefined,
      address: values.address || undefined,
      latitude: values.latitude || undefined,
      longitude: values.longitude || undefined,
      name: values.name || undefined,
      companyName: values.companyName || undefined,
      city: values.city || undefined,
      addressNumber: values.addressNumber || undefined,
      references: values.references || undefined,
      phone: values.phone || undefined,
      state: values.state || undefined,
    };
  },

  address(values) {
    return {
      province: values.province || undefined,
      postalCode: values.postalCode || undefined,
      complement: values.complement || undefined,
      address: values.address || undefined,
      id: values.id || undefined,
      latitude: values.latitude || undefined,
      longitude: values.longitude || undefined,
      city: values.city || undefined,
      addressNumber: values.addressNumber || undefined,
      references: values.references || undefined,
      type: (values?.type === "principal" || !values.type)
        ? undefined
        : values.type,
      state: values.state || undefined,
    };
  },

  initialAddress(query, values) {
    return {
      id: query === "default" ? null : values.id,
      type: query === "default" ? "principal" : values.type,
      postalCode: values.postalCode || "",
      city: values.city || "",
      state: values.state || "",
      address: values.address || "",
      addressNumber: values.addressNumber || "",
      province: values.province || "",
      complement: values.complement || "",
      references: values.references || "",
      latitude: values.latitude || undefined,
      longitude: values.longitude || undefined,
    };
  },

  initialAddressValues(type) {
    return {
      id: null,
      type: type ?? "principal",
      postalCode: "",
      city: "",
      state: "",
      address: "",
      addressNumber: "",
      province: "",
      complement: "",
      references: "",
    };
  },

  user(query, values) {
    const { NOME_DOCUMENTO, TELEFONE } = window.$vars;

    switch (query) {
      case NOME_DOCUMENTO:
        return {
          name: values.name || undefined,
          cpfCnpj: values.lockedDoc || values.cpfCnpj || undefined,
          ...(values.company && {
            stateRegistration: values.stateRegistration || undefined,
          }),
        };
      case TELEFONE:
        return {
          phone: values.phone,
        };
      default:
        return {};
    }
  },

  initialUser(query, values) {
    const { EMAIL, NOME_DOCUMENTO, TELEFONE } = window.$vars;

    switch (query) {
      case NOME_DOCUMENTO:
        return {
          name: values.name || "",
          cpfCnpj: values.cpfCnpj || "",
          company: values.company || false,
          stateRegistration: values.stateRegistration || "",
        };
      case TELEFONE:
        return {
          phone: values.phone || "",
        };
      case EMAIL:
        return {
          email: values.email || "",
        };
      default:
        return {};
    }
  },

  schema(query) {
    const { EMAIL, NOME_DOCUMENTO, TELEFONE } = window.$vars;

    switch (query) {
      case NOME_DOCUMENTO:
        return schema.user.NOME_DOCUMENTO;
      case TELEFONE:
        return schema.user.TELEFONE;
      case EMAIL:
        return schema.user.EMAIL;
      default:
        return schema.user.ME;
    }
  },
};
