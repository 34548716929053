import Big from "@/plugins/big";

const calcOptions = {
  ignorePromotionalItems: false,
  ignoreDiscountRules: false,
  ignoreListPrice: false,
};

function getPrice(c, options = calcOptions) {
  if (!options.ignorePromotionalItems && !options.ignoreDiscountRules && !options.ignoreListPrice) {
    return Big(c.item.discountInfo?.price || c.item.listPrice || c.item.promotionalPrice || c.item.price);
  }
  if (options.ignorePromotionalItems && !options.ignoreDiscountRules && !options.ignoreListPrice) {
    return Big(c.item.discountInfo?.price || c.item.listPrice || c.item.price);
  }
  if (options.ignorePromotionalItems && options.ignoreDiscountRules && options.ignoreListPrice) {
    return Big(c.item.price);
  }
  if (options.ignorePromotionalItems && options.ignoreDiscountRules && !options.ignoreListPrice) {
    return Big(c.item.listPrice || c.item.price);
  }
  if (options.ignorePromotionalItems && !options.ignoreDiscountRules && options.ignoreListPrice) {
    return Big(c.item.discountInfo?.price || c.item.price);
  }
  if (!options.ignorePromotionalItems && options.ignoreDiscountRules && options.ignoreListPrice) {
    return Big(c.item.promotionalPrice || c.item.price);
  }
  if (!options.ignorePromotionalItems && options.ignoreDiscountRules && !options.ignoreListPrice) {
    return Big(c.item.listPrice || c.item.promotionalPrice || c.item.price);
  }
  if (!options.ignorePromotionalItems && !options.ignoreDiscountRules && options.ignoreListPrice) {
    return Big(c.item.discountInfo?.price || c.item.promotionalPrice || c.item.price);
  }
  return Big(c.item.price);
}

export function calcTotal(items, options = calcOptions) {
  return items.reduce((a, c) => {
    const acc = Big(a);
    const price = getPrice(c, options);
    const quantity = Big(c.quantity);
    return acc.add(price.mul(quantity)).toFixedNumber();
  }, 0);
}

export function calcTotalWithoutPromoItems(items, options = calcOptions) {
  options.ignorePromotionalItems = true;
  return items.filter((v) => !v.item?.promotionalPrice).reduce((a, c) => {
    const acc = Big(a);
    const price = getPrice(c, options);
    const quantity = Big(c.quantity);
    return acc.add(price.mul(quantity)).toFixedNumber();
  }, 0);
}

export const getDiscountInfo = (data, discountRule) => {
  const currentItem = data?.item || data;

  if (!currentItem || !discountRule) {
    return data;
  }
  const item = { ...currentItem };
  const price = Big(item.listPrice || item.promotionalPrice || item.price);
  const rule = discountRule;
  const payment = rule.paymentMethod;

  const r = {
    price,
    discountValue: Big(rule.discountValue),
    discountType: rule.discountType,
    label: payment.label,
  };

  if (rule.discountType === "percentage") {
    const discount = price.mul(r.discountValue.div(100));
    r.price = price.sub(discount);
    r.label = `${Math.floor(r.discountValue.toNumber())}% desconto`;
  } else {
    r.price = price.sub(r.discountValue);
    r.label = `${window.$utils.BRL(r.discountValue.toNumber())}`;
  }

  const billingType = payment.type === "gateway" && payment.supportedTypes
    ? JSON.parse(payment.supportedTypes)[0]
    : null;

  if (["card", "simple"].includes(payment.type)) {
    r.label = `${r.label} ${payment.label}`;
  } else if (billingType === "pix") {
    r.label = `${r.label} no PIX`;
  } else {
    r.label = `${r.label} no cartão`;
  }

  r.price = r.price.toNumber();
  r.discountValue = r.discountValue.toNumber();

  item.discountInfo = r;
  if (data.item) {
    data.item = item;
    return data;
  }
  return item;
};

export const getDiscountLabel = (discountRule, subtotal) => {
  const payment = discountRule.paymentMethod;
  const discountValue = Big(discountRule.discountValue);
  let title = payment.label;
  let icon = "mdi-sale-outline";
  let total = Big(subtotal);

  if (discountRule.discountType === "percentage") {
    const discount = total.mul(discountValue.div(100));
    total = total.sub(discount);
    title = `${Math.floor(discountValue.toNumber())}% desconto`;
  } else {
    total = total.sub(discountValue);
    title = `${window.$utils.BRL(discountValue.toNumber())}`;
  }

  const billingType = payment.type === "gateway" && payment.supportedTypes
    ? JSON.parse(payment.supportedTypes)[0]
    : null;

  if (["card", "simple", "pix"].includes(payment.type)) {
    title = `${title} ${payment.label}`;
    icon = payment.type === "card"
      ? "mdi-credit-card-fast-outline"
      : "mdi-cash-fast";
  } else if (billingType === "pix") {
    title = `${title} no PIX`;
    icon = "mdi-rhombus-split";
  } else {
    title = `${title} no Cartão`;
    icon = "mdi-credit-card-outline";
  }

  return { title, icon, total: total.toNumber() };
};
