import { defineStore } from "pinia";
import schema from "@/http/apollo/schema";
import storage from "./modules/storage";
import config from "../assets/data/defaultConfig";
import { useAccountStore } from "./accountStore";

const Smart = window.Smart || config;
const STORAGE_PATH = Smart.env.STORAGE_PATH;

export const useConfigStore = defineStore("configStore", {
  state: () => ({
    currentPath: Smart.name,
    currentConfig: Smart,
    currentStore: Smart.store?.id,
    storeGuid: Smart.store?.guid,
    orderSuccess: false,
    storeDetails: storage.storeDetails(),
    providers: Smart.providers,
    termsPolicy: storage.termsPolicy(),
    aboutUs: Smart.about_us,
    // Styles
    background_color: "#fff",
    theme_color: "#2d407d",
    theme: "light",
    themes: Smart.themes,
    // drawer
    drawer: false,
  }),

  getters: {
    ecommerceActive() {
      return window.Smart.ecommerce;
    },
    appointmentsActive() {
      return window.Smart.appointments;
    },
    config(state) {
      return state.currentConfig;
    },
    storeConfig(state) {
      return state.currentConfig?.config || {};
    },
    storeTitle(state) {
      if (!state.currentPath) {
        return "Smart Delivery";
      }
      if (state.storeDetails?.name) {
        return window.$utils.toTitleCase(state.storeDetails.name);
      }
      return state.currentPath === "smartdelivery"
        ? "Smart Delivery"
        : window.$utils.capitalize(state.currentPath);
    },
    storeBanners() {
      return this.config?.banners;
    },
    logoAppBar(state) {
      const s = state.storeGuid;
      return `${STORAGE_PATH}/smartdelivery/${s}/res/logo-app-bar.png`;
    },
    logoSignIn(state) {
      const s = state.storeGuid;
      return `${STORAGE_PATH}/smartdelivery/${s}/res/logo-signin.png`;
    },
    ignoreStock(state) {
      const cfg = state.currentConfig;
      return cfg?.config?.ignoreStock ?? false;
    },
    hideItemsWithoutStock(state) {
      const cfg = state.currentConfig;
      return cfg?.config?.hideItemsWithoutStock ?? false;
    },
    listPriceId() {
      const account = useAccountStore();
      if (!account.verifiedCompanyUser) {
        return null;
      }
      if (!this.storeConfig.listPriceCompanyUsers) {
        return null;
      }
      const listPrice = this.storeConfig.listPrice;
      if (account.isento) {
        return listPrice?.isentoId ?? listPrice?.id ?? null;
      }
      return listPrice?.id ?? null;
    },
    appleUri(state) {
      return state.providers.apple.url;
    },
    appleClientId(state) {
      return state.providers.apple.clientId;
    },
    links(state) {
      return state.currentConfig?.config?.links || {};
    },
    docsBasePath() {
      return this.links.docs || "https://docs.smartdelivery.shop/main/";
    },
    policy() {
      return `${this.docsBasePath}#politica-de-privacidade`;
    },
    terms() {
      return `${this.docsBasePath}termos-e-condi%C3%A7%C3%B5es-de-uso.html`;
    },
    themeColor(state) {
      return state.theme_color;
    },
    primaryColor(state) {
      const dark = state.theme === "dark";
      return dark ? undefined : "primary";
    },
    primaryTextClass(state) {
      const dark = state.theme === "dark";
      return dark ? "" : "primary--text";
    },
  },

  actions: {
    async getStoreDetails() {
      const { data } = await window.$apollo.query({
        query: schema.store.STORE,
        variables: { id: this.currentStore },
      });
      this.storeDetails = data.store;
      storage.setStoreDetails(data.store);
    },

    setThemeColors(value) {
      if (value.background_color) {
        this.background_color = value.background_color;
      }
      if (value.theme_color) {
        this.theme_color = value.theme_color;
      }
      if (value.theme) {
        this.theme = value.theme;
      }
    },

    setTermsPolicy(value) {
      this.termsPolicy = value;
      storage.setTermsPolicy(value);
    },

    setDrawer(value) {
      this.drawer = value;
    },
  },
});